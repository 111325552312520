import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VideoYoutube from "../components/videoYoutube"
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}))

const GalleryPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="gallery" />
      <div className={classes.root} s>
        <Grid container justify="center" className={classes.paper}>
          <Grid item xs={8}>
            <Typography component="h4" variant="h6" align="center">
              La Réunion with Adrénal'ile on 30sec
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/tHAxAgCw8LI"
              videoTitle="La Réunion avec Adrénal'ile en 30sec"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography component="h4" variant="h6" align="center">
              La Réunion with Adrénal'île
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/6z3wFQl_x-w"
              videoTitle="La Réunion avec Adrénal'île"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography component="h4" variant="h6" align="center">
              The art of hair - Hair Salon Promo Trailer
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/UrnhqYedQK0"
              videoTitle="The art of hair - Hair Salon Promo Trailer"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography component="h4" variant="h6" align="center">
              Pure Bliss Villa - White Pearl
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/WTOIgwkdLJ4"
              videoTitle="Pure Bliss Villa - White Pearl"
            />
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default GalleryPage
