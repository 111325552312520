import React from "react"
const VideoYoutube = ({ videoSrcURL, videoTitle }) => (
  <div
    style={{
      position: "relative",
      height: "0",
      overflow: "hidden",
      maxWidth: "100%",
      paddingBottom: "56.25%",
    }}
  >
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default VideoYoutube
